import React, { useState } from 'react';
import { TextField, PrimaryButton, Stack, Label } from '@fluentui/react';
import WebRTCPage from './WebRTCPage';
import './App.css';

// Styling for Fluent UI components
const stackStyles = { root: { width: 300, margin: '0 auto', textAlign: 'center', marginTop: '50px' } };
const buttonStyles = { root: { marginTop: '20px' } };

const App: React.FC = () => {
    const [extension, setExtension] = useState<string>('');
    const [initialized, setInitialized] = useState<boolean>(false);

    const handleInit = () => {
        if (extension) setInitialized(true);
    };

    return (
        <div>
            {!initialized ? (
                <Stack styles={stackStyles} tokens={{ childrenGap: 20 }}>
                    <Label>Enter your Extension Number</Label>
                    <TextField
                        label="Extension Number"
                        value={extension}
                        onChange={(e, newValue) => setExtension(newValue || '')}
                        placeholder="Enter extension"
                    />
                    <PrimaryButton
                        text="Initialize"
                        onClick={handleInit}
                        disabled={!extension}
                        styles={buttonStyles}
                    />
                </Stack>
            ) : (
                <WebRTCPage extension={extension} />
            )}
        </div>
    );
};

export default App;
