//@ts-nocheck
import React, { useEffect } from "react";
import { init } from "ys-webrtc-sdk-ui";
import "ys-webrtc-sdk-ui/lib/ys-webrtc-sdk-ui.css";

interface WebRTCPageProps {
  extension: string;
}

const WebRTCPage: React.FC<WebRTCPageProps> = ({ extension }) => {
  useEffect(() => {
    const container = document.getElementById("webrtc-container");

    const yeastarSecret = process.env.REACT_APP_YEASTAR_SECRET;
    const yeastarPbxUrl = process.env.REACT_APP_YEASTAR_PBX_URL;

    if (!yeastarSecret || !yeastarPbxUrl) {
      console.error(
        "Environment variables REACT_APP_YEASTAR_SECRET or REACT_APP_YEASTAR_PBX_URL are not set."
      );
      return;
    }

    init(container as HTMLElement, {
      username: extension,
      secret: yeastarSecret,
      pbxURL: yeastarPbxUrl,
      enableLog: true,
    })
      .then(({ phone, pbx, destroy }) => {
        console.log("Yeastar SDK Initialized:", { phone, pbx });
        phone.on("startSession", ({ callId, session }) => {
          const { status } = session;
          if (status.communicationType === "outbound") {
            // Outbound call.
            // Update the user interface to display 'Calling', indicating that the callee side is ringing.
            console.log(status)
          } else {
            console.log(status)
            // Inbound call.
            // Update the user interface to display 'Connecting'.
          }
        });

      })
      .catch((err) => {
        console.error("Initialization Error:", err);
      });

    return () => {
      // Clean up or destroy the SDK instance if needed
    };
  }, [extension]);

  return (
    <div id="webrtc-container" style={{ width: "100%", height: "100%" }}></div>
  );
};

export default WebRTCPage;
